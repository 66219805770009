<template>
  <div>
    <b-card
      @click="selectCliente"
      class="rounded-lg cursor card-cliente"
    >
      <b-icon-x-circle
        class="close cursor"
        @click.stop="unselect"
        v-if="selected && selected.cod_cliente"
      ></b-icon-x-circle>
      <div class="d-flex justify-content-between">
        <div>
          <b-avatar
            size="80"
            :src="
              selected && selected.foto && selected.foto.length > 0
                ? selected.foto[0].fileURL
                : null
            "
          ></b-avatar>
        </div>
        <div class="flex-fill text-left pl-4">
          <h4 v-if="!selectedId">Selecione um cliente</h4>
          <h4 v-else>
            {{
              selected && selected.Nome
                ? selected.Nome
                : "Cliente não encontrado"
            }}
          </h4>
          <div v-if="selected && selected.Telefone">
            {{ selected.Telefone }}
          </div>
        </div>
      </div>
    </b-card>
    <b-modal
      :id="`modal` + idS"
      hide-footer
      size="lg"
      body-class="p-2"
      title="Selecione um cliente"
    >
      <clientes
        :onModal="true"
        @selectCliente="clienteSelecionado"
      ></clientes>
    </b-modal>
  </div>
</template>

<script>
import Clientes from "../../views/Configuracoes/Cadastros/Clientes.vue";
import ClientesLib from "../../libs/ClientesLib";
export default {
  name: "SelectClienteCard",
  components: { Clientes },
  props: {
    clienteID: {
      type: Number,
      default: null,
    },
  },
  mounted() {},
  data() {
    return {
      selectedId: null,
      idS: Math.random().toString(36).substring(7),
      selected: null,
    };
  },
  watch: {
    clienteID: {
      handler: function (val) {
        console.log("val", val);
        // if (val) {
        // this.selectedId = this.clienteID;
        // this.selected = val;
        this.clienteSelecionado(this.clienteID, false);
        // }
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    selectCliente() {
      // console.log("selectCliente", id);
      this.$bvModal.show(`modal` + this.idS);
    },
    unselect() {
      this.selectedId = null;
      this.selected = null;
      this.$emit("selected", null);
    },
    async clienteSelecionado(id, send = true) {
      this.selectedId = id;
      if (!id) {
        this.selectedId = null;
        this.selected = { Nome: "", Telefone: "", status: "not" };
      } else {
        let result = await ClientesLib.getAllClientes({ cod_cliente: id });
        console.log("result", result);
        if (result && result.success) {
          this.selected = result.data[0];
          this.$bvModal.hide(`modal` + this.idS);
          if (send) {
            this.$emit("selected", this.selected);
          }
        }
        console.log("id", id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-cliente {
  transition: all 0.3s ease;
  &:hover {
    background-color: #dedede;
    // color: #88d63b;
  }
}
</style>
