import axios from "@/utils/axios";

const get = async () => {
  let result = [];
  await axios
    .get("mesas/get")
    .then((data) => {
      result = data;
    })
    .catch((err) => {
      result = err;
    });

  return result;
};

const store = async (data) => {
  let result;

  await axios
    .post("mesas/store", data)
    .then((res) => (result = res))
    .catch((error) => (result = error.response));

  return result;
};

const getHistorico = async (cod_empresa, data) => {
  let result = [];
  try {
    result = (await axios.post("/mesas/historico", { cod_empresa, data })).data
      .data;
  } catch (Err) {
    console.log("ERRO AO BUCSAR HISTORICO DE MESAS", Err);
  }
  return result;
};

const getMesaControle = async (id) => {
  let result = [];
  try {
    result = (
      await axios.post("/mesas/findMesaControle", {
        id,
        data: "",
        showOpens: true,
        openeds: true,
      })
    ).data.data;
  } catch (Err) {
    console.log("ERRO AO BUCSAR HISTORICO DE MESAS", Err);
  }
  return result;
};

const getComandaAbertaPorCliente = async (cod_cliente, showAll = false) => {
  let result = [];
  try {
    result = (
      await axios.post("/mesas/getComandaAbertaPorCliente", {
        cod_cliente,
        showAll,
      })
    ).data.data;
  } catch (Err) {
    console.log("ERRO AO BUCSAR HISTORICO DE MESAS", Err);
  }
  return result;
};

const getMesasAbertasAll = async (cod_empresa,status="aberta", data="") => {
  let result = [];
  try {
    result = (await axios.post("/caixaNew/getMesasAbertas", { cod_empresa, status, data }))
      .data.data;
  } catch (Err) {
    console.log("ERRO AO BUCSAR HISTORICO DE MESAS", Err);
  }
  return result;
};

export default {
  get,
  store,
  getHistorico,
  getMesaControle,
  getComandaAbertaPorCliente,
  getMesasAbertasAll
};
