var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[(_vm.mesa && _vm.mesa.id > 0 && _vm.grupoSelecionado.Grupo == 'PIZZA')?_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-1",modifiers:{"collapse-1":true}}],staticClass:"d-block d-sm-none",attrs:{"variant":"primary","block":""}},[_vm._v(" Tamanho "+_vm._s(_vm.preco_tipo ? _vm.preco_tipo.tipo : "")+" ")]):_vm._e(),(
        _vm.$store.state.tipos_preco &&
        _vm.$store.state.tipos_preco.length > 0 &&
        _vm.grupoSelecionado.Grupo == 'PIZZA'
      )?_c('div',{staticClass:"col-12 col-sm-8 pr-sm-1 opTamanho",class:{ 'col-sm-12': _vm.mesa }},[_c('b-collapse',{staticClass:"mt-2",style:(_vm.mesa ? 'margin:0 auto; width:100%' : ''),attrs:{"id":"collapse-1","visible":_vm.tamanhoPizzaCollapse}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b',{staticClass:"mr-3",staticStyle:{"font-size":"25px"}},[_vm._v("Tamanho ")]),_c('b-form-radio-group',{class:{ 'w-100': _vm.mesa },attrs:{"id":"btn-radios-2","options":_vm.$store.state.tipos_preco.map((it) => {
                return { value: it, text: it.tipo };
              }),"aria-describedby":ariaDescribedby,"button-variant":"outline-secondary","size":_vm.isMobile ? 'lg' : 'sm',"name":"radio-btn-outline","buttons":"","fill":"","stacked":_vm.$store.state.isMobile},model:{value:(_vm.preco_tipo),callback:function ($$v) {_vm.preco_tipo=$$v},expression:"preco_tipo"}})]}}],null,false,2326825552)})],1)],1):_vm._e()],1),(_vm.mesa && _vm.mesa.id > 0)?_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-2",modifiers:{"collapse-2":true}}],staticClass:"d-block d-sm-none mt-1",attrs:{"variant":"primary","block":""}},[_vm._v(" Opções ")]):_vm._e(),_c('div',{staticClass:"col-12 col-sm-3 pl-sm-1",class:{ 'w-100 col-sm-12 pr-sm-3 pl-sm-3': _vm.mesa }}),_c('b-modal',{attrs:{"id":"modal-add-item-mobile","hide-footer":"","size":"lg","content-class":"prodModal","no-fade":"","no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.$bvModal.hide('modal-add-item-mobile')}}},[_c('cardapio-produto-lista',{attrs:{"add":_vm.add,"grupoSelecionado":_vm.grupoSelecionado,"produtosShow":_vm.produtosShow,"mesa":_vm.mesa,"isMobile":_vm.isMobile,"subgrupos":_vm.subgrupos,"opc_selecionada":_vm.opc_selecionada,"empresaSelecionada":_vm.empresaSelecionada,"preco_tipo":_vm.preco_tipo}})],1),(
      !_vm.isMobile ||
      (_vm.grupoSelecionado.Grupo == 'PIZZA' && (!_vm.mesa || _vm.mesa.id <= 0)) ||
      _vm.grupoSelecionado.Grupo != 'PIZZA'
    )?_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-2","visible":_vm.saborCollapse}},[_c('cardapio-produto-lista',{attrs:{"add":_vm.add,"grupoSelecionado":_vm.grupoSelecionado,"produtosShow":_vm.produtosShow,"mesa":_vm.mesa,"isMobile":_vm.isMobile,"subgrupos":_vm.subgrupos,"opc_selecionada":_vm.opc_selecionada,"empresaSelecionada":_vm.empresaSelecionada,"preco_tipo":_vm.preco_tipo},on:{"close":function($event){return _vm.$emit('closeAndSave')}}})],1):_vm._e(),(_vm.itemComAdicional)?_c('b-modal',{attrs:{"id":"modal-addds","title":"Adicionais","hide-footer":""}},[_c('b-table',{staticStyle:{"font-size":"10px"},attrs:{"items":_vm.itemComAdicional.ingredientes,"small":"","fields":[
        { key: 'ingrediente', label: 'Opção' },
        { key: 'preco_venda', label: 'Valor' },
        { key: 'opc', label: '' },
      ]},scopedSlots:_vm._u([{key:"cell(preco_venda)",fn:function(row){return [(!row.item.preco_venda || row.item.preco_venda <= 0)?_c('span',[_vm._v(" Grátis ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(row.item.preco_venda))+" ")])]}},{key:"cell(opc)",fn:function(row){return [_c('quantidade',{attrs:{"Model":row.item,"showRemover":false}})]}}],null,false,3102264566)}),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 text-center"},[_c('h3',[_vm._v(" "+_vm._s(_vm._f("currencyMask")((_vm.itemComAdicional.preco_venda + _vm.itemComAdicional.ingredientes.reduce((ret, vl) => { ret += (vl.preco_venda || 0) * (vl.qtd || 0); return ret; }, 0))))+" ")])]),_c('div',{staticClass:"col-6 text-center"},[_c('b-btn',{attrs:{"variant":"success","block":"","size":"lg"},on:{"click":_vm.addAdicional}},[_vm._v("ADICIONAR")])],1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }