<template>
  <div>
    <div
      class="menuPai d-sm-block"
      :class="{
        'd-none': !$store.state.menuVisivel,
        menuPaiMobile: $store.state.menuVisivel,
      }"
    >
      <!-- <div style="color:white">{{top}}</div> -->
      <router-link
        v-for="mnu in menu"
        :key="`menu_` + mnu.id + Math.random(999)"
        :class="{
          rtl: mnu.rtl,
        }"
        :to="
          mnu.link == '#' && mnu.children && mnu.children.length > 0
            ? mnu.children[0].link
            : mnu.link
        "
        @mouseover.native="submenu(mnu, true)"
        @click.native="submenu(mnu, null)"
        :ref="`alink${mnu.nome}`"
        @mouseout.native="submenu(mnu, false)"
        activeClass="selecionado"
      >
        <img
          :src="require('@/assets/img/icones/menu/' + mnu.icon)"
          v-if="mnu.iconType == 'png'"
        />
        <div :class="{}">
          <i
            :class="
              mnu.icon +
              (mnu.notifications && notificacoes > 0 ? ' startRing' : '')
            "
            v-if="mnu.iconType == 'fa'"
            style="font-size: 35px"
          ></i>
        </div>
        <div
          class="alerta"
          v-if="mnu.notifications && notificacoes > 0"
        >
          {{ notificacoes }}
        </div>
        <div>{{ mnu.nome }}</div>
      </router-link>

      <div
        v-for="gp in menuChild"
        :key="`_sub${gp.id}`"
        :class="{ rtl: gp.rtl }"
        style="top: 0"
        class="sub-menu"
        :ref="`submnu${gp.nome}`"
        @mouseover="submenu(gp, true)"
        @mouseout="submenu(gp, false)"
      >
        <h4 class="text-left p-3 text-white">
          <img
            :src="require('@/assets/img/icones/menu/' + gp.icon)"
            v-if="gp.iconType == 'png'"
          />
          <i
            :class="gp.icon"
            v-if="gp.iconType == 'fa'"
            style="font-size: 40px"
          ></i>
          {{ gp.nome }}
        </h4>
        <div
          v-for="mnu in gp.grupos"
          :key="'gpa' + mnu.grupo"
          class="subMenuGrupo"
        >
          <div class="titulo">{{ mnu.grupo }}</div>
          <router-link
            v-for="mnu2 in mnu.lista"
            :key="mnu.lista.indexOf(mnu2)"
            class="subMenuItem"
            :to="mnu2.link"
            activeClass="selecionado"
            @click.native="
              menuClose();
              submenu(gp, false);
            "
          >
            <img
              :src="require('@/assets/img/icones/menu/' + mnu2.icon)"
              v-if="mnu2.iconType == 'png'"
            />
            <i
              :class="mnu2.icon"
              v-if="mnu2.iconType == 'fa'"
            ></i>
            <div>{{ mnu2.nome }}</div>
          </router-link>
        </div>
      </div>

      <b-modal
        body-bg-variant="dark"
        body-text-variant="white"
        hide-footer
        hide-header
        v-for="mnu in menuChild"
        :key="`_sub2${mnu.id}`"
        :id="`_sub2${mnu.id}`"
        :class="{ rtl: mnu.rtl }"
        style="height: 100%"
        class="sub-menu text-white modal-fixed"
        body-class="modal-fixed"
        :ref="`submnu${mnu.nome}`"
      >
        <div
          class="d-flex flex-column"
          style="overflow-y: auto; height: 100%"
        >
          <h4 class="text-center">
            <img
              :src="require('@/assets/img/icones/menu/' + mnu.icon)"
              v-if="mnu.iconType == 'png'"
            />
            <i
              :class="mnu.icon"
              v-if="mnu.iconType == 'fa'"
              style="font-size: 40px"
            ></i>
            {{ mnu.nome }}
          </h4>
          <div
            v-for="mnu2 in mnu.children"
            :key="mnu.children.indexOf(mnu2)"
            class="subMenuGrupo"
          >
            <div
              class="text-white text-center d-flex w-100 flex-1-1 jusify-content-start align-items-center mb-4"
              @click="hideSubmenu(`_sub2${mnu.id}`, mnu2.link)"
              activeClass="selecionado"
            >
              <span style="width: 60px">
                <img
                  :src="require('@/assets/img/icones/menu/' + mnu2.icon)"
                  v-if="mnu2.iconType == 'png'"
                  class="mx-auto"
                />
                <i
                  :class="mnu2.icon"
                  v-if="mnu2.iconType == 'fa'"
                  style="font-size: 40px"
                  class="mx-auto"
                ></i>
              </span>
              <div
                style="flex: 1"
                class="text-left pl-4"
              >
                {{ mnu2.nome }}
              </div>
            </div>
            <hr />
          </div>
        </div>
      </b-modal>

      <div
        class="userName text-left w-100"
        @click="alterarCaixa()"
        style=""
      >
        <div class="w-100 text-center">
          <small> Versão {{ version }}</small>
          <hr class="mt-1 mb-1 wpt-0 pb-0" />
        </div>
        <b-icon-person style="margin-right: 5px" />
        {{ $store.state.auth.user.nome }}<br />

        <div
          style=""
          class="mt-1"
        >
          <small
            >{{ $store.state.empresa_ativa.nome.substring(0, 20) || "" }}
            <br />
            {{
              $store.state.caixa_ativo
                ? $store.state.caixa_ativo.nome.substring(0, 10) || ""
                : ""
            }}
          </small>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-edita-caixa"
      hide-footer
      :title="`Alterar Caixa`"
    >
      <template #modal-header="{ close }">
        <i class="fa fa-home"></i> Sessão
        <i
          class="fa fa-times"
          @click="close()"
          style="float: right"
        ></i>
      </template>
      <h4>
        <i class="fa fa-user"></i>
        {{ $store.state.auth.user.nome }}
        <a
          href="/logout"
          class="float-right"
        >
          <i class="fa fa-power-off"></i>
        </a>
      </h4>
      <empresa-combo
        :selecionar="changeEmpresa"
        :selected="$store.state.empresa_ativa.cod_empresa"
        :disabled="$store.state.auth.user.tipo != 'Administrador'"
        :noStyle="false"
      />
      <caixa-combo
        @ch="changeCaixa($event)"
        :caixa="
          $store.state.caixa_ativo ? $store.state.caixa_ativo.cod_caixa : 0
        "
        :ComTodos="false"
        :noStyle="false"
      />
      <div
        class="p-3 text-left bg-menu p-3 pt-1 pb-1 text-white"
        v-if="
          $store.state.auth.user.tipo == 'Administrador' ||
          $store.state.auth.user.tipo.indexOf('Gerente') >= 0 ||
          $store.state.auth.user.tipo == 'Gerente Caixa'
        "
      >
        <label class="font-weight-bold">
          <i class="fa fa-clock"></i> Tabela de Previsão Média de Entrega
        </label>

        <table
          class="table table-dark"
          style="font-size: 12px"
        >
          <thead>
            <th>Local</th>
            <th>Prazo</th>
          </thead>
          <tbody>
            <tr
              v-for="empresa in empresas"
              :key="`emp_` + empresa.cod_empresa"
            >
              <td>{{ empresa.nome }}</td>
              <td>
                <b-input
                  v-model="empresa.previsao_entrega"
                  class="text-center"
                  style="font-size: 12px"
                  autocomplete="off"
                />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2">
                <b-button
                  block
                  variant="primary"
                  @click="alterarEmpresas"
                  ><i class="fa fa-save"></i> Alterar</b-button
                >
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import CaixaCombo from "../empresa/CaixaCombo.vue";
import EmpresaCombo from "../empresa/EmpresaCombo.vue";
import axios from "../../utils/axios";

export default {
  name: "MenuComp",
  sockets: {
    connect() {
      // Fired when the socket connects.
      // console.log("------>", this.$socket);
      // console.log("joinin a room", this.codToSock());
      // this.$socket.io.join(this.codToSock());
      this.$socket.emit("room", this.codToSock());
      this.solicitarNotifica();
    },
    atualizarEmpresas(cod_empresa) {
      cod_empresa;
      // console.log(
      //   "atualizar Empresas",
      //   cod_empresa,
      //   this.$store.state.auth.user
      // );
      // if (
      //   this.$store.state.auth.user &&
      //   this.$store.state..auth.user.cod_empresa == cod_empresa &&
      //   this.$store.state.auth.user.cod_caixa == 4 &&
      //   this.$store.state.auth.user.nivel <= 1
      // ) {
      //   this.$store.dispatch(
      //     "auth/atualizaEmpresas",
      //     this.$store.state.auth.user
      //   );
      // }
    },

    disconnect() {},

    solicitarNotificacoes() {
      this.solicitarNotifica();
    },
    // Fired when the server sends something on the "messageChannel" channel.
    notificacoesNaoLidas(data) {
      // console.log("recebeu notificacoes NAO LIDAS >>>> ", data);
      this.$store.state.notificacoes = data;
      this.notificacoes = data;
      try {
        if (data && data > 0) {
          var audio = new Audio(
            "https://proxy.s-zap.com/http://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
          );
          audio.play();
        }
      } catch {
        console.log("error");
      }
    },
  },

  components: {
    CaixaCombo,
    EmpresaCombo,
  },
  data() {
    return {
      version: "1.1.8",
      menu: [],
      menuChild: [],
      top: 0,
      notificacoes: [],
      empresas: [
        this.$store.state.empresas,
        ...this.$store.state.empresas.empresas,
      ],
      alertaTimer: null,
    };
  },
  mounted() {
    this.carregaMenus();
    // console.log("menu", this.menuChild);
    this.verificaVersa();

    // console.log(this.$socket);
    this.solicitarNotifica();
  },
  watch: {
    "$store.state.caixa_ativo": function () {
      // console.log("caixa ativo", this.$store.state.caixa_ativo);
      this.$socket.emit("room", this.codToSock());
      this.$forceUpdate();
    },
    "$store.state.empresa_ativa": {
      deep: true,
      handler() {
        // console.log("MENU ALTERADO");
        this.carregaMenus();
      },
    },
  },
  methods: {
    hideSubmenu(id, to) {
      this.$bvModal.hide(id);
      this.$router.push(to);
      console.log(123, id);
    },
    carregaMenus() {
      // console.log("user", this.$store.state.auth.user);
      this.menu = this.$store.state.menu
        .filter((x) =>
          this.$store.state.auth.user.ifood !== 1 ? x.nome !== "IFood" : true
        )
        .filter((x) => x.exibe)
        .filter(
          (x) =>
            !this.$store.state.empresa_ativa ||
            !this.$store.state.empresa_ativa.configs ||
            !this.$store.state.empresa_ativa.configs.tipoCaixas ||
            this.$store.state.empresa_ativa.configs.tipoCaixas !== "Simples" ||
            (this.$store.state.empresa_ativa.configs.tipoCaixas &&
              this.$store.state.empresa_ativa.configs.tipoCaixas ===
                "Simples" &&
              x.nome != "Caixas")
        )
        .filter(
          (x) =>
            !this.$store.state.empresa_ativa ||
            !this.$store.state.empresa_ativa.configs ||
            !this.$store.state.empresa_ativa.configs.tipoCaixas ||
            this.$store.state.empresa_ativa.configs.tipoCaixas !==
              "Conferencia" ||
            (this.$store.state.empresa_ativa.configs.tipoCaixas &&
              this.$store.state.empresa_ativa.configs.tipoCaixas ===
                "Conferencia" &&
              x.nome !== "Caixa")
        )
        .filter(
          (x) =>
            !this.$store.state.empresa_ativa ||
            !this.$store.state.empresa_ativa.configs ||
            !this.$store.state.empresa_ativa.configs.modoSistema ||
            (this.$store.state.empresa_ativa.configs.modoSistema == "1" &&
              !x.nome.includes("Clientes")) ||
            // &&
            // x.nome != "Caixa"
            (this.$store.state.empresa_ativa.configs.modoSistema == "2" &&
              !x.nome.includes("Entregas") &&
              !x.nome.includes("Caixas") &&
              !x.nome.includes("Fiscal") &&
              !x.nome.includes("Entregas"))
        )
        .map((m) => {
          if (m.children && m.children.length > 0) {
            m.grupos = m.children.reduce((ret, vl) => {
              let fnd = ret.find((x) => x.grupo == vl.grupo);
              if (!fnd) {
                ret.push({
                  grupo: vl.grupo,
                  lista: [vl],
                });
              } else {
                fnd.lista.push(vl);
              }
              return ret;
            }, []);
          }
          return m;
        });
      this.menuChild = this.$store.state.menu
        .filter((x) => x.exibe && x.children && x.children.length > 0)
        .filter((x) =>
          !this.$store.state.empresa_ativa.integracoes.some(
            (x) => x.Sistema == "IFood"
          )
            ? x.nome != "IFood"
            : true
        )
        .map((m) => {
          if (m.children && m.children.length > 0) {
            m.grupos = m.children.reduce((ret, vl) => {
              let fnd = ret.find((x) => x.grupo == vl.grupo);
              if (!fnd) {
                ret.push({
                  grupo: vl.grupo,
                  lista: [vl],
                });
              } else {
                fnd.lista.push(vl);
              }
              return ret;
            }, []);
          }
          return m;
        });

      // console.log("mnus", this.menu);
    },
    codToSock() {
      // let cod = this.$store.state.empresa_ativa.cod_empresa;
      // if (this.$store.state.caixa_ativo.cod_caixa == 4) {
      //   cod = "0";
      // }
      return "USER_" + this.$store.state.auth.user.cod_usuario;
    },
    async alterarEmpresas() {
      const ok = await axios.post("/empresas/previsao_entrega", {
        empresas: this.empresas,
      }).data;
      if (ok) {
        this.$swal.fire({
          title: "Informações salvas com sucesso!",
          icon: "success",
        });
      }
    },
    solicitarNotifica() {
      // console.log("joinin a room", this.codToSock());

      // console.log("solicitando notificacoes não lidas");
      this.$socket.emit("room", this.codToSock());

      // console.log("------>", this.$socket);
      let user = this.$store.state.auth.user;
      user.cod_caixa = this.$store.state.caixa_ativo.cod_caixa;
      this.$socket
        //.to(this.codToSock())
        .emit("solicitarNotificacoesNaoLidas", user);
    },
    notificacao() {
      if (this.notificacoes.length == 0) {
        this.notificacoes.push(1);
      } else {
        this.notificacoes.pop();
      }
    },
    changeEmpresa(emp) {
      // console.log(emp);
      this.$store.state.empresa_ativa = emp;
    },
    changeCaixa(cx) {
      console.log("CHANGEING CAIXA", cx);
      this.$store.state.caixa_ativo = this.$store.state.caixas.find(
        (x) => x.cod_caixa == cx.value
      );
    },
    alterarCaixa() {
      this.$bvModal.show("modal-edita-caixa");
    },
    menuClose() {
      // console.log("entrou1111111111111111111111");
      this.$store.state.menuVisivel = false;
    },
    getImgUrl(pet) {
      //var images = require.context(pet);
      //return images();
      return require(pet);
    },
    async verificaVersa() {},
    submenu(mnu, enter) {
      if (window.innerWidth >= 575) {
        if (
          mnu.children &&
          mnu.children.filter((x) => x.exibe && x.exibe === true).length > 0
        ) {
          if (enter || enter == null) {
            this.$refs[`alink${mnu.nome}`][0].$el.classList.add("selecionado");
            this.$refs[`submnu${mnu.nome}`][0].classList.add(
              "sub-menu-visible"
            );

            this.top =
              this.$refs[`alink${mnu.nome}`][0].$el.getBoundingClientRect()
                .top / 1.3;
            if (
              this.top +
                this.$refs[`submnu${mnu.nome}`][0].getBoundingClientRect()
                  .height >
              window.innerHeight
            ) {
              this.top = -1;
            }
            this.$refs[`submnu${mnu.nome}`][0].style.cssText =
              this.top > 0
                ? "top: " + this.top + "px !important"
                : "top:auto; bottom:0px !important";
          } else {
            this.$refs[`alink${mnu.nome}`][0].$el.classList.remove(
              "selecionado"
            );
            this.$refs[`submnu${mnu.nome}`][0].classList.remove(
              "sub-menu-visible"
            );
          }
        } else {
          this.menuClose();
        }
      } else if (enter == null) {
        this.menuClose();

        this.$bvModal.show(`_sub2${mnu.id}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/menu.scss";
.alerta {
  position: absolute;
  background-color: red;
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  top: 5px;
  right: 5px;
  padding: 5px;
  font-size: 11px;
  font-weight: bold;
  transition: 0.3 all ease;
}
</style>
