var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.onModal ? '' : 'container-fluid'},[_c('barra-crud',{attrs:{"botaoNovo":true,"onFiltrar":(txt) => {
        _vm.filtro = txt;
      },"breadcrumb":false,"busca-aberta":true,"onNovo":() => _vm.openCliente(null),"size-bt":"lg","show-title":!_vm.onModal,"nome-customizado":'Cliente'}}),(_vm.loading)?_c('div',{staticClass:"text-center p-5"},[_c('b-spinner',{attrs:{"large":"","size":"lg"}}),_c('br'),_vm._v(" carregando... ")],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-left",class:_vm.onModal ? '' : 'p-lg-5'},[_c('b-table',{ref:"tbLista",attrs:{"items":_vm.lista,"current-page":_vm.currentPage,"per-page":_vm.perPage,"hover":"","select-mode":"single","selectable":"","bordered":false,"filter":_vm.filtro,"stacked":"sm","fields":[
          { key: 'cod_cliente', label: '', searchable: true },
          { key: 'Nome', label: 'Nome', sortable: true, class: 'w-50' },
          { key: 'Telefone', label: 'Telefone', sortable: true },
          {
            key: 'saldo',
            label: 'Saldo',
            sortable: true,
            class: 'text-center ' + (_vm.onModal ? 'd-none' : ''),
          },
          { key: 'situacao', label: 'Situação' },
          {
            key: 'situacao_acesso',
            label: 'Acesso',
            class: _vm.onModal ? 'd-none' : '',
          },
          {
            key: 'opc',
            label: '',
            class: _vm.onModal ? '' : 'd-none',
          },
        ]},on:{"row-selected":(its) =>
            its && its.length > 0
              ? _vm.openCliente(its[0].cod_cliente, _vm.onModal)
              : _vm.openCliente(null, true)},scopedSlots:_vm._u([{key:"cell(cod_cliente)",fn:function(row){return [_c('b-avatar',{attrs:{"size":"lg","src":!row.item.foto || row.item.foto.length <= 0
                ? `https://ui-avatars.com/api/?name=${row.item.Nome}&size=128&background=777&color=fff`
                : row.item.foto[0].fileURL}})]}},{key:"cell(saldo)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item.saldo))+" ")]}},{key:"cell(opc)",fn:function(row){return [_c('div',{staticClass:"d-flex"},[(_vm.onModal)?_c('b-btn',{ref:"btSel",attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.selectCliente(row.item)}}},[_vm._v("Selecionar")]):_vm._e(),_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openCliente(row.item.cod_cliente, false)}}},[_vm._v("Editar")])],1)]}}],null,false,3837070998)}),_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),(_vm.editId && _vm.onModal)?_c('div',{staticClass:"p-5 mx-5 w-100"},[_c('b-btn',{ref:"btSel",attrs:{"block":"","size":"lg","variant":"success"},on:{"click":function($event){return _vm.selectCliente(null)}}},[_vm._v("SELECIONAR CLIENTE")])],1):_vm._e()]):_vm._e(),_c('b-modal',{staticClass:"prodModal",attrs:{"id":"modal-form-cliente","hide-footer":"","size":"md","content-class":'prodModal',"body-class":"insideModal p-0 pt-2","no-fade":"","no-footer":"","title":!_vm.editId ? 'Cadastrando Cliente' : 'Editando Cliente'},on:{"hidden":_vm.closeForm}},[_c('cliente-completo',{attrs:{"id":_vm.editId},on:{"close":_vm.closeForm,"closeAndOpen":_vm.closeFormSel}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }