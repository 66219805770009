<template>
  <div>
    <div
      class="d-flex flex-wrap"
      v-if="loading"
    >
      <b-skeleton
        v-for="n in 20"
        :key="n"
        class="m-1"
        style="min-width: 300px; min-height: 90px; margin-right: 10px"
      >
      </b-skeleton>
    </div>
    <div>
      <div class="row">
        <div class="col-12 col-md-6">
          <h5>Comandas</h5>
        </div>
        <div class="col-12 col-md-6 text-right">
          <b-radio-group
            v-model="filtro"
            :options="[
              { text: 'Abertas', value: 'abertas' },
              { text: 'Fechadas', value: 'fechadas' },
              { text: 'Todas', value: 'todas' },
            ]"
            size="sm"
          />
        </div>
      </div>
      <b-table
        class="mt-2"
        :items="listFiltred"
        small
        hover
        :fields="[
          { key: 'nome', label: 'Comanda', sortable: true },
          { key: 'dt_aberta', label: 'Abertura', sortable: true },
          { key: 'dt_fechada', label: 'Fechamento', sortable: true },
          { key: 'vl_total', label: 'Total', sortable: true },
        ]"
        style="font-size: 11px"
        @row-clicked="openComanda"
        selectable
        ref="tbLista"
      >
        <template #cell(dt_aberta)="row">
          {{ row.item.dt_aberta | moment("DD/MM/YYYY HH:mm") }}
        </template>
        <template #cell(dt_fechada)="row">
          {{ row.item.dt_fechada | moment("DD/MM/YYYY HH:mm") }}
        </template>
        <template #cell(vl_total)="row">
          {{ row.item.vl_total | currency }}
        </template>
      </b-table>

      <b-modal
        id="modal-open-comanda"
        size="xl"
        hide-footer
        :modal-class="
          $store.state.isMobile ? `modal-full p-0 ` : 'modal-full p-0'
        "
        :body-class="`p-0`"
        :content-class="`p-0 ${$store.state.isMobile ? `fixed` : 'fixed'}`"
      >
        <mesa-new
          :mesa="mesaSelected"
          :blocked="true"
          :hideMesaMenu="true"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import MesaLib from "../../libs/MesaLib";
import MesaNew from "../../views/mesas/MesaNew.vue";

export default {
  components: { MesaNew },
  props: {
    cod_cliente: {
      type: Number,
      default: null,
    },
  },
  mounted() {
    this.loadComandas();
  },
  data() {
    return {
      lista: [],
      mesaSelected: null,
      loading: false,
      filtro: "todas",
    };
  },
  watch: {},
  computed: {
    listFiltred() {
      console.log("filtro", this.filtro);
      return this.lista.filter((item) => {
        if (this.filtro == "abertas") {
          return item.dt_fechada == null;
        } else if (this.filtro == "fechadas") {
          return item.dt_fechada != null;
        }
        return true;
      });
    },
  },
  methods: {
    async loadComandas() {
      this.loading = true;
      this.lista = await MesaLib.getComandaAbertaPorCliente(
        this.cod_cliente,
        true
      );
      console.log("this.lista", this.lista);
      this.loading = false;
    },
    async openComanda(item) {
      console.log("item", item);

      if (item && item.mesa_controle_id) {
        let result = await MesaLib.getMesaControle(item.mesa_controle_id);
        console.log("result", result);
        if (result && result.length > 0) {
          this.mesaSelected = result[0];
          this.$bvModal.show("modal-open-comanda");
        } else {
          this.$bvToast.toast("Mesa não encontrada", {
            title: "Erro",
            variant: "danger",
            solid: true,
          });
        }
      }

      this.$refs.tbLista.clearSelected();
    },
  },
};
</script>

<style lang="scss" scoped></style>
