var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((20),function(n){return _c('b-skeleton',{key:n,staticClass:"m-1",staticStyle:{"min-width":"300px","min-height":"90px","margin-right":"10px"}})}),1):_vm._e(),_c('div',[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-12 col-md-6 text-right"},[_c('b-radio-group',{attrs:{"options":[
            { text: 'Abertas', value: 'abertas' },
            { text: 'Fechadas', value: 'fechadas' },
            { text: 'Todas', value: 'todas' },
          ],"size":"sm"},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}})],1)]),_c('b-table',{ref:"tbLista",staticClass:"mt-2",staticStyle:{"font-size":"11px"},attrs:{"items":_vm.listFiltred,"small":"","hover":"","fields":[
        { key: 'nome', label: 'Comanda', sortable: true },
        { key: 'dt_aberta', label: 'Abertura', sortable: true },
        { key: 'dt_fechada', label: 'Fechamento', sortable: true },
        { key: 'vl_total', label: 'Total', sortable: true },
      ],"selectable":""},on:{"row-clicked":_vm.openComanda},scopedSlots:_vm._u([{key:"cell(dt_aberta)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("moment")(row.item.dt_aberta,"DD/MM/YYYY HH:mm"))+" ")]}},{key:"cell(dt_fechada)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("moment")(row.item.dt_fechada,"DD/MM/YYYY HH:mm"))+" ")]}},{key:"cell(vl_total)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item.vl_total))+" ")]}}])}),_c('b-modal',{attrs:{"id":"modal-open-comanda","size":"xl","hide-footer":"","modal-class":_vm.$store.state.isMobile ? `modal-full p-0 ` : 'modal-full p-0',"body-class":`p-0`,"content-class":`p-0 ${_vm.$store.state.isMobile ? `fixed` : 'fixed'}`}},[_c('mesa-new',{attrs:{"mesa":_vm.mesaSelected,"blocked":true,"hideMesaMenu":true}})],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-6"},[_c('h5',[_vm._v("Comandas")])])
}]

export { render, staticRenderFns }